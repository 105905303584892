import  React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const PrivacyPage = () => (
    <Layout page="privacy-page">
      <Seo title="PRIVACY"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>PRIVACY POLICY</span></h2>
      </section>
      <section className="page-content full-layout">
        <div className="main-content">
          <div className="content-block">
            <p>株式会社新宮商行（以下「当社」）は、以下のとおり個人情報保護方針を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性の認識と取り組みを徹底させることにより、個人情報の保護を推進いたします。</p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>個人情報の管理</span></h3>
            <p>当社は、お客様の個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持・管理体制の整備・社員教育の徹底等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行います。</p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>個人情報の利用目的</span></h3>
            <p>お客様からお預かりした個人情報は、当社からのご連絡や業務のご案内、ご質問に対する回答として、電子メールや資料のご送付に利用いたします。</p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>個人情報の第三者への開示・提供の禁止</span></h3>
            <p className="mb-5">当社は、お客様よりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
            <p>・お客様の同意がある場合<br />
              ・お客様が希望されるサービスを提案するために当社が業務を委託する業者に対して開示する場合<br />
              ・法令に基づき開示することが必要である場合
            </p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>個人情報の安全対策</span></h3>
            <p>当社は、個人情報の正確性および安全性確保のために、セキュリティに万全の対策を講じています。
            </p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>ご本人の照会</span></h3>
            <p>お客様がご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認のうえ、対応させていただきます。
            </p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>法令、規範の遵守と見直し</span></h3>
            <p>当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。
            </p>
          </div>
          <div className="content-block">
            <h3 className="sub-title"><span>お問い合わせ</span></h3>
            <p className="mb-5">当社の個人情報の取り扱いに関するお問い合わせは下記までご連絡ください。
            </p>
            <p>
              株式会社 新宮商行 SHINGU SHOKO, LTD.<br />
              〒047-0032 北海道小樽市稲穂2丁目1-1<br />
              TEL ： 0134-24-1311(代) / <br className="sp" />FAX ： 0134-22-8717(代)
            </p>
          </div>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>プライバシーポリシー</li>
        </ul>
      </section>
    </Layout>
);

export default PrivacyPage;
